import { Text } from '@components'
import { Badge, EventPropertyRow, ExternalUrl } from '@new-components'
import { getTruncatedAddress } from '@utils'
import { Purchase } from 'tokensoft-shared-types'
import { DocumentSigningOverview } from './my-purchases-card'

type Props = {
  purchase: Purchase
  transactionUrl: HttpsUrl
  buyerWallet: EvmAddress
  documentsSigningOverview: DocumentSigningOverview[]
}

const HistoricalPurchaseItem = ({
  purchase,
  transactionUrl,
  buyerWallet,
  documentsSigningOverview,
}: Props) => {
  const formatDate = (date: Date): string => {
    const pad = (num: number) => num.toString().padStart(2, '0')

    let hours = date.getHours()
    const minutes = pad(date.getMinutes())
    const ampm = hours >= 12 ? 'pm' : 'am'

    hours = hours % 12 || 12
    const formattedHours = pad(hours)

    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const year = date.getFullYear()

    return `${month}.${day}.${year} ${formattedHours}:${minutes} ${ampm}`
  }

  return (
    <div className='flex flex-col gap-4 h-auto'>
      <div className='flex justify-between items-start flex-wrap'>
        <div className='flex flex-col justify-start gap-1'>
          <Text className='text-sm font-medium'>
            {formatDate(purchase.createdAt)}
          </Text>
          <Text className='text-xs font-light text-nowrap'>
            Purchase completed successfully
          </Text>
        </div>
        <ExternalUrl text='Transaction Details' url={transactionUrl} />
      </div>
      <div className='grid grid-cols-2 w-full gap-4 sm:gap-x-16 md:gap-x-32'>
        <EventPropertyRow
          label='Wallet'
          value={getTruncatedAddress(buyerWallet)}
        />
      </div>
      <div>
        {documentsSigningOverview.map((document, index) => (
          <div key={index} className='flex flex-col gap-2'>
            <ExternalUrl
              text={document.documentName}
              url={document.documentUrl}
            />
            {document.requiresSignature && document.wasSigned && (
              <Badge className='bg-green-200' text='Signed' />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default HistoricalPurchaseItem
