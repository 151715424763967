import {
  useGetChain,
  useGetProject,
  useGetSaleByContractAddress,
  usePurchaseCompleted,
} from '@apiServices'
import { Button, LoadingIndicator, StatusIconInfo } from '@components'
import { PurchaseInputsLoader } from '@contexts'
import { Card, ChainGate } from '@newComponents'
import { getFlatPriceSaleABI } from '@utils'
import { useState } from 'react'
import { Abi, TransactionReceipt, decodeEventLog } from 'viem'
import { TSEvent } from '../../../../../types/src/types/web/read'
import { EventModal } from '../eligibility/event-modal'
import SalePurchaseLimit from './event-properties/sale-purchase-limit'
import { NodePurchaseFlow } from './node-sale/node-purchase-flow'
import { TokenPurchaseFlow } from './token-sale/token-purchase-flow'

interface PurchaseCardProps {
  event: TSEvent
  saleAddress: EvmAddress
}

export const PurchaseCard = ({ event, saleAddress }: PurchaseCardProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  // Fetch sale data
  const {
    data: sale,
    isPending: isSaleLoading,
    error: saleError,
  } = useGetSaleByContractAddress(saleAddress)

  const {
    data: chain,
    isPending: isChainPending,
    error: chainError,
  } = useGetChain(sale?.chainId)

  const { mutate: purchaseCompleted } = usePurchaseCompleted()
  const { data: project } = useGetProject()

  if (isSaleLoading || isChainPending) {
    return <LoadingIndicator />
  }

  if (saleError || chainError) {
    return (
      <div>
        Error loading sale data: {saleError?.message ?? chainError?.message}
      </div>
    )
  }

  if (!sale || !chain) {
    return <div>No sale data available.</div>
  }

  if (chain.nativePriceOracleAddress === null) {
    return <div>Native price oracle address not found.</div>
  }

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const handleFinishedPurchasing = (receipt?: TransactionReceipt) => {
    if (!receipt) {
      return
    }

    const abi = getFlatPriceSaleABI('v4.0') as Abi

    const [buyEvent] = receipt.logs
      .map((log) => {
        try {
          return decodeEventLog({
            abi,
            data: log.data,
            topics: log.topics,
          })
        } catch (_error) {
          // no-op
        }
      })
      .filter((event) => {
        return event?.eventName === 'Buy'
      }) as [any]

    if (!buyEvent) {
      // TODO: report failure to sentry

      return closeModal()
    }

    purchaseCompleted({
      receipt: {
        baseCurrencyValue: buyEvent.args?.baseCurrencyValue.toString(),
        buyer: buyEvent.args?.buyer,
        platformTokenFee: buyEvent.args?.platformTokenFee.toString(),
        protocolTokenFee: buyEvent.args?.protocolTokenFee.toString(),
        token: buyEvent.args?.token,
        tokenValue: buyEvent.args?.tokenValue.toString(),
      },
      event: {
        name: event.name,
        type: event.type,
      },
      project: {
        name: project?.name ?? '',
      },
      sale: {
        symbol: sale.token.symbol,
      },
    })

    closeModal()
  }

  const handleChainChanged = (_chainId: ChainId, isRequiredChain: boolean) => {
    if (!isRequiredChain) {
      setModalOpen(false)
    }
  }

  const isSaleCapMet = sale.totalPurchases.value.gte(sale.saleCap.value)

  let assetName: string = ''
  switch (sale.assetType) {
    case 'node':
      assetName = 'License'
      break
    case 'token':
      assetName = 'Tokens'
      break
  }

  return (
    <Card>
      <div className='font-bold text-black mb-3 text-xl'>
        Purchase {assetName}
      </div>
      <div className='flex flex-col gap-4'>
        {/* Purchase limits section */}
        <div className='bg-blue-50 p-4 rounded-md flex gap-2'>
          <StatusIconInfo />
          <div>
            <div className='flex items-center gap-2'>
              <span className='font-medium text-[#667085]'>
                Purchase Limits for this event:
              </span>
            </div>
            <div className='text-[#667085]'>
              <SalePurchaseLimit
                assetType={sale.assetType}
                pricePerAsset={sale.token.price.value}
                propertyName='minimum'
                purchaseLimit={sale.minimumPurchase}
              />
              <SalePurchaseLimit
                assetType={sale.assetType}
                pricePerAsset={sale.token.price.value}
                propertyName='maximum'
                purchaseLimit={sale.maximumPurchase}
              />
            </div>
          </div>
        </div>
        <ChainGate
          requiredChainId={sale.chainId}
          onChainChanged={handleChainChanged}
        >
          <PurchaseInputsLoader eventId={event.id}>
            {/* Purchase button */}
            <Button
              className='btn btn-primary hover:opacity-80 text-white font-bold py-3 
                        rounded-full mx-auto w-full'
              onClick={openModal}
              disabled={isSaleCapMet}
            >
              Purchase
            </Button>
            {/* Show message if sale cap met */}
            {isSaleCapMet && (
              <div className='bg-yellow-100 p-4 flex flex-col items-center rounded-md'>
                <div className='flex items-center gap-2 mb-2'>
                  {/* Include your icon here */}
                  <h4 className='text-lg font-bold'>
                    This event has sold out.
                  </h4>
                </div>
                <p>
                  The sale cap for this event has been met. No more purchases
                  can be made.
                </p>
              </div>
            )}
            {/* Modal for the purchase flow */}
            <EventModal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel='Purchase Modal'
            >
              {sale.assetType === 'node' ? (
                <NodePurchaseFlow
                  sale={sale}
                  onFinished={handleFinishedPurchasing}
                />
              ) : (
                <TokenPurchaseFlow
                  sale={sale}
                  onFinished={handleFinishedPurchasing}
                />
              )}
            </EventModal>
          </PurchaseInputsLoader>
        </ChainGate>
      </div>
    </Card>
  )
}
