import { useWebApiClient } from '@api/clients'
import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { Purchase, PurchaseRaw } from 'tokensoft-shared-types'

const parsePurchase = (raw: PurchaseRaw): Purchase => ({
  id: raw.id,
  uri: raw.uri as HttpsUrl,
  spent: new BigNumber(raw.spent),
  price: new BigNumber(raw.price),
  baseCurrencyValue: new BigNumber(raw.baseCurrencyValue),
  paymentMethod: {
    token: raw.paymentMethod.token as Maybe<EvmAddress>,
    isNative: raw.paymentMethod.native,
    decimals: raw.paymentMethod.decimals,
    symbol: raw.paymentMethod.symbol,
  },
  createdAt: new Date(parseInt(raw.createdAt, 10) * 1000),
  transactionHash: raw.transactionHash,
  buyerAddress: raw.buyer.id,
  saleAddress: raw.sale.id,
})

export const useGetMySalePurchases = (saleAddress: EvmAddress) => {
  const fetchWithClient = useWebApiClient()

  return useQuery<Purchase[]>({
    queryKey: ['sales', saleAddress, 'my-purchases'],
    queryFn: async () => {
      const purchasesRaw: PurchaseRaw[] = (await fetchWithClient(
        `sales/${saleAddress}/my-purchases`,
      )) as PurchaseRaw[]

      const purchases: Purchase[] = purchasesRaw.map(parsePurchase)

      purchases.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())

      return purchases
    },
    enabled: saleAddress !== '0x',
  })
}
