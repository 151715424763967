import { useGetChain, useMyEventUser } from '@apiServices'
import { LoadingIndicator, Text } from '@components'
import { useEventDocuments, useTSAddress } from '@hooks'
import { CollapsibleCard } from '@newComponents'
import { getTxUrl } from '@utils'
import { twMerge } from 'tailwind-merge'
import {
  EventDocument,
  EventUserDocumentStatus,
  Purchase,
} from 'tokensoft-shared-types'
import HistoricalPurchaseItem from './historical-purchase-item'

export interface DocumentSigningOverview {
  documentName: string
  documentUrl: HttpsUrl
  requiresSignature: boolean
  wasSigned: boolean
}

type MyPurchasesCardProps = {
  eventId: ID
  purchases: Purchase[]
  // saleAddress: EvmAddress
  chainId: ChainId
  className?: string
}

export const MyPurchasesCard = ({
  eventId,
  purchases,
  // saleAddress,
  chainId,
  className = '',
}: MyPurchasesCardProps) => {
  const {
    data: chain,
    isPending: isPendingChain,
    error: chainError,
  } = useGetChain(chainId)

  // const {
  //   data: sale,
  //   isPending: isPendingSale,
  //   error: saleError,
  // } = useGetSaleByContractAddress(saleAddress)

  const {
    data: eventUser,
    isPending: isPendingEventUser,
    error: eventUserError,
  } = useMyEventUser(eventId)

  const {
    data: eventDocumentData,
    isPending: isPendingEventDocuments,
    error: eventDocumentsError,
  } = useEventDocuments(eventId)

  const mapDocumentsToSigningOverview = (
    documents: EventDocument[],
    userStatuses: EventUserDocumentStatus[],
  ): DocumentSigningOverview[] => {
    const statusMap = new Map<number, EventUserDocumentStatus>(
      userStatuses.map((status) => [status.eventDocumentVersionId, status]),
    )

    return documents.map((document) => ({
      documentName: document.name,
      documentUrl: document.uri as HttpsUrl,
      requiresSignature: document.requiresSignature,
      wasSigned: Boolean(statusMap.get(document.documentId)?.signedAt),
    }))
  }

  const { address } = useTSAddress()

  if (
    isPendingChain ||
    // isPendingSale ||
    isPendingEventUser ||
    isPendingEventDocuments
  ) {
    return <LoadingIndicator />
  }

  if (chainError /* || saleError */ || eventUserError || eventDocumentsError) {
    return (
      <Text className='text-red-500'>
        {chainError?.message ||
          // saleError?.message ||
          eventUserError?.message ||
          eventDocumentsError?.message}
      </Text>
    )
  }

  // const purchaseCurrencyValues: CurrencyValue[] = purchases.map((purchase) => {
  //   return {
  //     value: purchase.amount.div(10 ** sale.token.decimals),
  //     symbol: sale.token.symbol,
  //   }
  // })

  const purchaseTransactionUrls: HttpsUrl[] = purchases.map(
    (purchase) => getTxUrl(purchase.transactionHash, chain) as HttpsUrl,
  )

  const buyerWallet = eventUser?.beneficiary_wallet ?? address ?? '0x'
  return (
    <CollapsibleCard
      cardTitle='Purchases'
      className={twMerge(purchases.length === 0 ? 'hidden' : '', className)}
    >
      <div className='flex flex-col gap-6'>
        {purchases?.map((purchase, index) => (
          <div
            key={index}
            className='pb-6 first:pt-4 last:pb-0 border-b last:border-none'
          >
            <HistoricalPurchaseItem
              purchase={purchase}
              transactionUrl={purchaseTransactionUrls[index]}
              // purchaseCurrencyValue={purchaseCurrencyValues[index]}
              buyerWallet={buyerWallet}
              documentsSigningOverview={mapDocumentsToSigningOverview(
                eventDocumentData.eventDocuments ?? [],
                eventDocumentData.eventUserDocumentsStatus ?? [],
              )}
            />
          </div>
        ))}
      </div>
    </CollapsibleCard>
  )
}
