import {
  useGetMyDistributorClaims,
  useGetMySalePurchases,
  useGetProjectTheme,
  useRefreshEventUserEligibility,
} from '@apiServices'
import {
  EventContent,
  EventHeader,
  EventHeaderWrapper,
  Timeline,
} from '@components'
import { CurrencyRegistryProvider, useToast } from '@contexts'
import { Card, CollapsibleCard, PageContent, StatusBox } from '@newComponents'
import * as Sentry from '@sentry/react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SocialLinks, TSEvent } from 'tokensoft-shared-types'
import { ClaimCard } from './distribution/claim-card'
import { EligibilityRequirementsMet } from './eligibility-requirements-met'
import { EventEligibilityCard } from './eligibility/event-eligibility-card'
import { EventModal } from './eligibility/event-modal'
import { EventCountdown } from './event-countdown'
import { EventHeaderCard } from './event-header-card'
import { DistributionDetailsCard } from './sale/distribution-details-card'
import { MyClaimsCard } from './sale/my-claims-card'
import { MyPurchasesCard } from './sale/my-purchases-card'
import { PurchaseCard } from './sale/purchase-card'
import { SaleDetailsCard } from './sale/sale-details-card'
import './ts-event-page.css'

interface TSEventPageProps {
  event: TSEvent
  projectSocials: SocialLinks
}

export const TSEventPage = ({ event, projectSocials }: TSEventPageProps) => {
  const { showErrorToast } = useToast()
  const { data: projectTheme } = useGetProjectTheme()
  const navigate = useNavigate()

  const isEventUpcoming = event.timeline.isEventUpcoming() === true

  /**
   * Eligibility requirements should not be fake-able, so it is tracked on the front end,
   * and once the front end has determined that the user has met the requirements, it will
   * send a request to the backend to update the user's eligibility status. The mutation
   * below is that request.
   */
  const [
    hasMetEligibilityRequirementsLocally,
    setHasMetEligibilityRequirementsLocally,
  ] = useState(false)

  const {
    mutateAsync: refreshEventUserEligibility,
    data: eligibilityData = null,
    error: eligibilityError,
  } = useRefreshEventUserEligibility()

  const [shouldShowRequirementsMetModal, setShouldShowRequirementsMetModal] =
    useState(false)
  const [showRequirementsMetModal, setShowRequirementsMetModal] =
    useState(false)

  const eventBadgeTitles = event.timeline.getRegistrationAndEventStatuses()
  if (event.isPrivateEvent) {
    eventBadgeTitles.unshift('Private Event')
  }

  useEffect(() => {
    let timeout: Maybe<NodeJS.Timeout> = null
    if (shouldShowRequirementsMetModal) {
      timeout = setTimeout(() => setShowRequirementsMetModal(true), 2000)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [shouldShowRequirementsMetModal])

  useEffect(() => {
    if (eligibilityError !== null) {
      showErrorToast({
        description: 'Error loading eligibility. Please try again.',
      })
      Sentry.captureException(eligibilityError)
    }
  }, [eligibilityError, showErrorToast])

  const {
    data: claims,
    isPending: isPendingDistributorClaims,
    error: distributorClaimsError,
  } = useGetMyDistributorClaims(event.distributorAddress ?? '0x')

  const {
    data: purchases,
    isPending: isPendingSalePurchases,
    error: salePurchasesError,
  } = useGetMySalePurchases(event.saleAddress ?? '0x')

  const handleBackToEvents = () => navigate('/')

  const handleDidMeetEligibilityRequirements = useCallback(() => {
    setHasMetEligibilityRequirementsLocally(true)

    void (async () => {
      // Request refresh to the user's eligibility status on back end
      try {
        await refreshEventUserEligibility(event.id)
      } catch (e: unknown) {
        console.error('Error refreshing event user eligibility')
        Sentry.captureException(e)
      }

      if (isEventUpcoming || event.timeline.startTime === null) {
        setShouldShowRequirementsMetModal(true)
      }
    })()
  }, [
    event.id,
    isEventUpcoming,
    event.timeline.startTime,
    refreshEventUserEligibility,
  ])

  const shouldRenderDistributionDetails =
    event.type === 'distribution' && event.distributorAddress !== null

  const saleIsOpen =
    event.type === 'sale' &&
    event.saleAddress !== null &&
    event.timeline.isEventOpen() === true

  return (
    <div className='overflow-hidden h-full'>
      {/* Render only on md & lg screens */}
      <div className='md:block hidden'>
        {!!event.content.header.backgroundImage && (
          <EventHeaderWrapper
            backgroundColor={event.content.header.backgroundColor}
            backgroundImage={event.content.header.backgroundImage}
          >
            <EventHeader
              headerTitle={''}
              headerSubtitle={''}
              contentHeaderImage={event.content.header.backgroundColor}
              contentHeaderFontColor={event.content.header.fontColor}
              themeLayoutVersion={'title_100'}
            />
          </EventHeaderWrapper>
        )}
      </div>
      <div className='overflow-y-auto h-full bg-[#f0f3fd] flex justify-center'>
        <CurrencyRegistryProvider>
          <PageContent onBack={handleBackToEvents}>
            <div className='main-grid mb-8'>
              <div className='main-col'>
                <EventHeaderCard
                  className='event-header'
                  eventTitle={event.name}
                  logo={event.iconLargeUrl ?? projectTheme?.logo ?? null}
                  eventDescription={event.content.header.subtitle}
                  shouldShowReferralButton={!event.isPrivateEvent}
                  socials={projectSocials}
                  badgeTitles={eventBadgeTitles}
                />
                {event.type === 'sale' && event.saleAddress !== null && (
                  <>
                    <div className='main-item'>
                      <SaleDetailsCard saleAddress={event.saleAddress} />
                    </div>
                    {salePurchasesError ? (
                      <Card className='main-item order-2'>
                        <StatusBox
                          status='error'
                          text={`There was an error loading your purchase history: ${salePurchasesError}.`}
                        />
                      </Card>
                    ) : (
                      !isPendingSalePurchases &&
                      purchases &&
                      purchases.length > 0 && (
                        <div className='main-item order-2'>
                          <MyPurchasesCard
                            eventId={event.id}
                            purchases={purchases}
                            // saleAddress={event.saleAddress}
                            chainId={event.chainId!}
                          />
                        </div>
                      )
                    )}
                  </>
                )}

                {event.type === 'distribution' &&
                  event.distributorAddress !== null && (
                    <>
                      <div className='main-item'>
                        <DistributionDetailsCard
                          distributorAddress={event.distributorAddress}
                        />
                      </div>
                      {distributorClaimsError ? (
                        <Card className='main-item order-2'>
                          <StatusBox
                            status='error'
                            text={`There was an error loading your claim history: ${distributorClaimsError}.`}
                          />
                        </Card>
                      ) : (
                        !isPendingDistributorClaims &&
                        claims &&
                        claims.length > 0 && (
                          <div className='main-item order-2'>
                            <MyClaimsCard
                              eventId={event.id}
                              claims={claims}
                              distributorAddress={event.distributorAddress}
                              chainId={event.chainId!}
                            />
                          </div>
                        )
                      )}
                    </>
                  )}
                <CollapsibleCard
                  className='main-item order-3'
                  cardTitle='Event Description'
                >
                  <EventContent
                    bodyContent={event.getCurrentBodyContent() ?? ''}
                  />
                </CollapsibleCard>
              </div>
              <div className='side-col'>
                <EventCountdown
                  eventType={event.type}
                  timeline={event.timeline}
                />
                <EventEligibilityCard
                  event={event}
                  kycRequirement={event.kycRestriction}
                  isPolkadotWalletRequired={event.isPolkadotWalletRequired}
                  highlightColor={projectTheme?.colors.primary ?? '#000'}
                  onMetEligibilityRequirements={
                    handleDidMeetEligibilityRequirements
                  }
                  isRegistrationOpen={
                    event.timeline.isRegistrationOpen() === true
                  }
                  useCustomBeneficiary={event.useCustomBeneficiary}
                />
                {saleIsOpen &&
                  hasMetEligibilityRequirementsLocally &&
                  eligibilityData?.isEligible === true &&
                  event.saleAddress !== null && (
                    <PurchaseCard
                      event={event}
                      saleAddress={event.saleAddress}
                    />
                  )}
                {shouldRenderDistributionDetails &&
                  event.distributorAddress !== null &&
                  hasMetEligibilityRequirementsLocally &&
                  eligibilityData?.isEligible === true && (
                    <ClaimCard
                      event={event}
                      className='order-1'
                      distributorAddress={event.distributorAddress}
                      chainId={event.chainId!}
                      isClaimAvailable={
                        event.timeline.isEventOpen() === true &&
                        hasMetEligibilityRequirementsLocally
                      }
                    />
                  )}
                <Card className='min-w-[286px] order-1'>
                  <Timeline timeline={event.timeline} />
                </Card>
              </div>
            </div>
          </PageContent>
        </CurrencyRegistryProvider>
      </div>
      <EventModal
        isOpen={showRequirementsMetModal}
        onRequestClose={() => setShowRequirementsMetModal(false)}
        contentLabel='Event Requirements Met!'
      >
        <EligibilityRequirementsMet
          eventType={event.type}
          isKycRequired={event.kycRestriction !== 'none'}
          onFinished={() => setShowRequirementsMetModal(false)}
          eventStartsAt={event.timeline.startTime}
          themeColors={projectTheme?.colors}
        />
      </EventModal>
    </div>
  )
}
