import { Text } from '@components'
import { twMerge } from 'tailwind-merge'
import { FormattedNumber } from './formatted-number'

type FormattedCurrencyValueProps = {
  currencyValue: CurrencyValue
  displayDecimals?: number
  displayCommas?: boolean
  trimTrailingZeros?: boolean
  className?: string
}

export const FormattedCurrency = ({
  currencyValue,
  displayDecimals = 6,
  displayCommas = true,
  trimTrailingZeros = true,
  className,
}: FormattedCurrencyValueProps) => {
  const minStableDecimals = 3
  const maxStableDecimals = 6
  const isStableCoin =
    currencyValue.symbol === 'USDC' || currencyValue.symbol === 'USDT'

  const minRoundingDecimals = isStableCoin ? minStableDecimals : 4
  const maxRoundingDecimals = isStableCoin ? maxStableDecimals : 6

  return (
    <Text className={twMerge('inline', className)}>
      {currencyValue.symbol === 'USD' ? '$' : ''}
      <FormattedNumber
        value={currencyValue.value}
        displayDecimals={displayDecimals}
        withCommas={displayCommas}
        trimTrailingZeros={trimTrailingZeros}
        minDecimals={minRoundingDecimals}
        maxDecimals={maxRoundingDecimals}
      />{' '}
      {`${currencyValue.symbol}`}
    </Text>
  )
}
